@import "colors";
@import "variables";

.ag-theme-white-basic {
  &.ag-theme-balham {
    .ag-root-wrapper {
      border: none;

      .ag-header-cell-resize {
        width: 8px;
        height: 100%;
        right: -2px;

        &:after {
          content: "";
            position: absolute;
            z-index: 1;
            display: block;
            left: calc(50% - 1px);
            width: 2px;
            height: 30%;
            top: 35%;
            background-color: rgba(186,191,199,.5);
            background-color: var(--ag-header-column-resize-handle-color,rgba(186,191,199,.5));
        }
      }

      .ag-body-container {
        margin-bottom: -3px;
      }

      .ag-pinned-left-cols-viewport-wrapper {
        border-right: 1px solid $brand-silver-minus4;
      }

      .ag-overlay-loading-center {
        display: none;
      }

      .ag-header-cell-label {
        width: calc(100% - 3px) !important;
      }

      .ag-header-cell-sorted-none, .ag-header-cell-sorted-asc, .ag-header-cell-sorted-desc {
        cursor: pointer;
      }

      .ag-root, .ag-header, .ag-row {
        border: 0 none;
        font: inherit;
      }

      .ag-root {
        border-top: 1px solid $brand-silver-minus4;
        z-index: 1;
      }

      .ag-header-cell {
        border-right: 1px solid $brand-silver-minus4;
        overflow: visible;
      }

      .ag-icon-desc {
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIyLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxNiAxNiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTYgMTY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDpub25lO3N0cm9rZTojMDBBQUZGO30KPC9zdHlsZT4KPHBhdGggY2xhc3M9InN0MCIgZD0iTTcuNSwxMlY0IE0xMC41LDYuNWwtMy0zbC0zLDMiLz4KPC9zdmc+Cg==');
      }

      .ag-icon-asc {
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIyLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxNiAxNiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTYgMTY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDpub25lO3N0cm9rZTojMDBBQUZGO30KPC9zdHlsZT4KPHBhdGggY2xhc3M9InN0MCIgZD0iTTcuNSwxMVYzIE00LjUsOC41bDMsM2wzLTMiLz4KPC9zdmc+Cg==');
      }

      .ag-row-drag {
        width: 16px;
        height: 16px !important;
        background-size: cover;
        cursor: grab;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgAQMAAADYVuV7AAAABlBMVEUAAAAzMzPI8eYgAAAAAXRSTlMAQObYZgAAABhJREFUeAFjGI5gFPyHAhpyRv0z+MEoAADfCn+ByhMuHwAAAABJRU5ErkJggg==');
      }

      .ag-row-dragging {
        cursor: grabbing;
      }

      .ag-header-cell {
        line-height: 40px;

        h4{
          line-height: 35px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &::after {
          display: none;
        }
      }

      .ag-cell {
        line-height: inherit;
        font-family: "Roboto", "Helvetica Neue";
        font-size: $font-size-base;
        color: $brand-silver-plus3;
        font-weight: normal;
        border-right: 1px solid $brand-silver-minus4;
        border-top: 1px solid $brand-silver-minus4;
        padding: 5px 11px 11px;
        user-select: text;

        p {
          font-weight: normal;
          font-size: $font-size-base;
          color: $brand-silver-plus3;

          &.buttons-cell {
            line-height: 30px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        button {
          display: none;
          float: right;
          margin-top: 0px;
        }
      }

      .ag-icon-grip:before {
        content: unset;
      }

      .ag-header-icon {
        height: 100%;
        display: flex;
        align-items: center;

        .ag-icon {
          width: 16px;
          height: 16px;

          &::before {
            content: unset;
          }
        }
      }

    }
  }

  &.ag-theme-balham .ag-header, .ag-row-odd {
    background-color: white;
  }


  .ag-header-row {
    color: $brand-silver-plus3;
    border-bottom: 0 none !important;
    font-size: $font-size-base;
    font-weight: bold;
  }

  .ag-row-hover {
    background-color: #fff;
  }

  //.ag-column-hover, .my-class {
  //  cursor: pointer;
  //
  //  &.ag-header-cell {
  //    background-color: $brand-silver-minus3;
  //    h4 {
  //      color: #fff;
  //    }
  //  }
  //  &.ag-cell {
  //    background-color: $brand-gray-minus4;
  //  }
  //}

  .ag-row-selected {
    background-color: #fff;
  }
}
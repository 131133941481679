@import "colors";

accordion {
  accordion-group {
    &.panel {
      box-shadow: 0px 11px 12px -4px rgba(0, 0, 0, 0.19);

      &:not(:first-child):not(.prev-item-open):not(.panel-open) {
        border-top: 1px solid $brand-silver-minus4;
      }

      &.prev-item-open {
        border-top-right-radius: $border-radius-base;
        border-top-left-radius: $border-radius-base;

        .panel-heading {
          border-top-right-radius: $border-radius-base;
          border-top-left-radius: $border-radius-base;
        }
      }

      &.next-item-open, &:last-child {
        border-bottom-right-radius: $border-radius-base;
        border-bottom-left-radius: $border-radius-base;

        .panel-heading {
          border-bottom-right-radius: $border-radius-base;
          border-bottom-left-radius: $border-radius-base;
        }
      }
    }

    .panel-heading {
      padding: 20px 24px;
    }
  }

  &.panel-group .panel + .panel {
    margin-top: 0px;
  }

  .panel-open:not(:first-child) {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .panel-open:first-child {
    margin-bottom: 10px !important;
  }

  //.panel-collapse.collapse.in.show {
  //  margin-bottom: 5px;
  //}

  accordion-group:first-child {
    &.group-0 {
      margin-bottom: 0px;

      &.panel-open {
        margin-bottom: $padding-large-vertical;
      }
    }
  }
}
@import "colors";
@import "variables";

.ag-theme-grey {

  &.ag-theme-balham {
    .ag-root-wrapper {
      border: none;

      .ag-header-cell-resize {
        width: 8px;
        height: 100%;
        right: -2px;
        opacity: 0;
      }

      .ag-row-selected {
        background-color: $brand-gray-minus4;
      }
      .ag-header-viewport{
        background-color: $brand-silver-minus5;
        border-bottom: 1px solid white;
      }

      .ag-root, .ag-header, .ag-row {
        border: 0 none;
        font: inherit;
      }

      .ag-header-cell-label {
        width: 100% !important;
      }

      .ag-header-cell-sorted-none, .ag-header-cell-sorted-asc, .ag-header-cell-sorted-desc {
        cursor: pointer;
      }

      .ag-icon-desc {
        background-size: 19px 19px;
        height: 54px;
        opacity: 1;
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIzLjAuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxNiAxNiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTYgMTY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDpub25lO3N0cm9rZTojNDU1QTY0O30KPC9zdHlsZT4KPHBhdGggY2xhc3M9InN0MCIgZD0iTTcuNSwxMlY0IE0xMC41LDYuNWwtMy0zbC0zLDMiLz4KPC9zdmc+Cg==');
      }

      .ag-icon-asc {
        background-size: 19px 19px;
        height: 54px;
        opacity: 1;
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIzLjAuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxNiAxNiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTYgMTY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDpub25lO3N0cm9rZTojNDU1QTY0O30KPC9zdHlsZT4KPHBhdGggY2xhc3M9InN0MCIgZD0iTTcuNSwxMVYzIE00LjUsOC41bDMsM2wzLTMiLz4KPC9zdmc+Cg==');
      }
      .ag-row-drag {
        width: 16px;
        height: 16px !important;
        background-size: cover;
        cursor: grab;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgAQMAAADYVuV7AAAABlBMVEUAAAAzMzPI8eYgAAAAAXRSTlMAQObYZgAAABhJREFUeAFjGI5gFPyHAhpyRv0z+MEoAADfCn+ByhMuHwAAAABJRU5ErkJggg==');
      }
      .ag-row-dragging .ag-row-drag{
        cursor: grabbing;
      }

      .ag-icon-grip:before {
        content: unset;
      }

      .ag-header-icon {
        height: 100%;
        display: flex;
        align-items: center;

        .ag-icon {
          width: 16px;
          height: 16px;

          &::before {
            content: unset;
          }
        }
      }
    }
  }

  &.ag-theme-balham .ag-header {
    background-color: $brand-silver-minus3;
  }

  .ag-header-container {
    border-bottom: 1px solid white;

    .ag-header-row {
      background-color: $brand-silver-minus3;
      border-bottom: 0 none;
      font-size: $font-size-base;
      font-weight: 700;
      color: white;

      .ag-header-cell {
        overflow: visible;
        line-height: 50px;
        border: 0 none;
        border-right: 1px solid white;
        border-left: 1px solid white;
        border-bottom: 1px solid white;

        &.ag-header-cell-moving {
          background-color: $brand-silver-minus2;
        }
      }
    }
  }

  .ag-row {
    background-color: $brand-silver-minus5;
    font-size: $font-size-base;

    &:hover {
      background-color: $brand-silver-minus4;
    }

    .ag-cell {
      font-family: "Roboto", "Helvetica Neue";
      font-size: $font-size-base;
      color: $brand-silver-plus3;
      font-weight: normal;
      line-height: 30px;
      padding-left: 10px;
      padding-right: 10px;
      user-select: text;

      p {
        font-weight: normal;
        font-size: $font-size-base;
        color: $brand-silver-plus3;
        width: 100%;
        margin: 8px 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.text-right {
          text-align: right;
        }
      }
    }

    .ag-cell.row-span {
      padding-left: 0px;
      padding-right: 0px;

      .ag-span-cell {
        padding-left: 10px;
        padding-right: 10px;
        border-bottom: 2px solid white;
        width: 100%;

        p {
          white-space: nowrap;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 5px 0 5px;
        }

        &:last-child {
          border-bottom: 0 none;
        }
      }
    }

  }

  .ag-cell, .ag-header-cell {
    border: 1px solid white;
  }

}
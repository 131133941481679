@import "colors";

.keyboard-focus {
    &:focus {
        outline: 0 !important;
    }

    &:focus-visible {
        border-color: 1px solid $brand-sky;
        outline: 0;
        box-shadow: 0 0 6px 2px rgba(0, 170, 255, 0.6) !important;
    }
}

.helper-text {
    font-size: 12px;
    color: $brand-silver-minus1;
    font-weight: 400;
}

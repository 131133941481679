@import "colors";
@import "variables";

.cdk-global-overlay-wrapper, .cdk-overlay-container { 
    z-index: 9999 !important;
    
    .cdk-overlay-pane {
        border: solid 1px $popover-border-color;
        border-radius: $border-radius-base;
        background-color: $popover-bg;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
        padding: 10px 10px;
    }
}
@import "colors";

$search-list-font-size: 12px;
$search-list-line-height: 16px;
$osl-background-color: white;

.ag-theme-searchlist {
    height: 100%;
    width: 100%;

    label {
        margin: 0;
    }

    .ag-body-container {
        margin-bottom: -5px; // don't change this, we (Amir & Dror) got do it after a lengthy debug session
    }

    .ag-root-wrapper {
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
        background: $osl-background-color;
        border-color:$brand-silver-minus4;
        border-radius: 0 0 2px 2px;
        box-sizing: border-box;
        z-index: 1;
        border-top: none;

        .ag-cell-wrapper {
            display: flex;
        }

        .ag-header-row {
            width: 100%!important;
        }
        .cell-wrap-text, .ag-header-cell {
            white-space: normal !important;
            cursor: pointer;
        }

        .ag-cell, .ag-header-cell {
            cursor: pointer;
            line-height: inherit;
            font-family: "Roboto", "Helvetica Neue";
            font-size: $search-list-font-size;
        }

        .ag-header-cell {
            border-top: 1px solid $brand-gray-minus2;
            width: 100%!important;
        }

        .ag-body-viewport {
            .ag-row-selected {
                background-color: $brand-silver-minus5;
            }
            .ag-row-hover {
                background-color: $brand-silver-minus5;
            }
        }

        .ag-floating-bottom {
            overflow-y: hidden!important;

            .ag-floating-bottom-viewport {
                border-top: 1px solid $brand-silver-minus4;
            }

            // work-around to force footer to full row width
            .ag-floating-bottom-container {
                padding-top: 10px;
                padding-bottom: 10px;
                width: 100%!important;
                .ag-cell {
                    width: 100%!important;
                }
            }
        }
    }
}


@import 'colors';
@import '../node_modules/@optimove/ui-sdk/assets/styles/colors-to-js.scss';
@import 'variables';

/** Accordion **/
@import "./app/components/accordion/accordion.scss";

/** searchlist **/
@import './app/components/optiSearchList/agThemeSearchlist.scss';

/** ag grid themes **/
@import './app/components/agGrid/agThemeGrey.scss';
@import './app/components/agGrid/agThemeWhite.scss';
@import './app/components/agGrid/agThemeWhiteBasic.scss';
@import '~@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
@import '~@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';
@import '~@ag-grid-enterprise/all-modules/dist/styles/ag-theme-alpine.css';

/** angular-tree **/
@import '~@circlon/angular-tree-component/css/angular-tree-component.css';

/** Modal **/
@import './app/components/optiLogicModal/optiLogicModalGlobal.scss';

@import "./styles/general.scss";
@import '~@angular/cdk/overlay-prebuilt.css';
@import './styles/popover.scss';
@import './styles/answerWidget.scss';

/** ng select theme **/
@import "~@ng-select/ng-select/themes/default.theme.css";

.page-container {
    height: calc(100vh - #{$navbar-height} - #{$navbar-margin-bottom});
    overflow: auto;
    position: relative;
}